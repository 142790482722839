
h1,h2,h3,h4,h5,h6 {
    font-family: var(--font-bold);
}

h1 {

    font-size:24px;
    @media (min-width: map-get($grid-breakpoints,sm)) and (max-width: map-get($grid-breakpoints,md)) {
        font-size:30px;
    }
    @media (min-width: map-get($grid-breakpoints,md)) {
        font-size: 46px;
        line-height: 68px;
    }

}

h2 {

    font-size:18px;
    @media (min-width: map-get($grid-breakpoints,sm)) and (max-width: map-get($grid-breakpoints,md)) {
        font-size: 32px;
    }
    @media (min-width: map-get($grid-breakpoints,md)) {
        font-size: 42px;
        line-height: 50px;
    }

}

h3 {

    font-size:20px;
    margin-bottom:12px;
    line-height: 1.3;
    @media (min-width: map-get($grid-breakpoints,sm)) and (max-width: map-get($grid-breakpoints,md)) {
        font-size: 22px;
    }
    @media (min-width: map-get($grid-breakpoints,md)) {
        margin-bottom:30px;
        font-size:36px;
        line-height: 48px;
    }

}

h4 {

    font-size:22px;
    line-height: 1.4;
    @media (min-width: map-get($grid-breakpoints,sm)) and (max-width: map-get($grid-breakpoints,md)) {
        font-size: 22px;
    }
    @media (min-width: map-get($grid-breakpoints,md)) {
        font-size: 36px;
        line-height: 42px;
    }

}

h5 {

    font-size:18px;
    @media (min-width: map-get($grid-breakpoints,sm)) and (max-width: map-get($grid-breakpoints,md)) {
        font-size: 22px;
    }
    @media (min-width: map-get($grid-breakpoints,md)) {
        font-size: 24px;
        line-height: 32px;
    }

}

h6 {

    font-size:18px;
    @media (min-width: map-get($grid-breakpoints,sm)) and (max-width: map-get($grid-breakpoints,md)) {
        font-size: 22px;
    }
    @media (min-width: map-get($grid-breakpoints,md)) {
        font-size: 18px;
        line-height: 28px;
    }
    
}


.level-one {

    font-size:16px;
    @media (min-width: map-get($grid-breakpoints,sm)) and (max-width: map-get($grid-breakpoints,md)) {
        font-size: 20px;
    }
    @media (min-width: map-get($grid-breakpoints,md)) {
        font-size: 20px;
    }
}

.level-two {

    font-size:14px;
    @media (min-width: map-get($grid-breakpoints,sm)) and (max-width: map-get($grid-breakpoints,md)) {
        font-size: 20px;
    }
    @media (min-width: map-get($grid-breakpoints,md)) {
        font-size: 16px;
    }

}

.level-three {

    font-size:14px;
    @media (min-width: map-get($grid-breakpoints,sm)) and (max-width: map-get($grid-breakpoints,md)) {
        font-size: 22px;
    }
    @media (min-width: map-get($grid-breakpoints,md)) {
        font-size: 14px;
        line-height: 20px;
    }

}

.level-four {

    font-size:12px;
    @media (min-width: map-get($grid-breakpoints,sm)) and (max-width: map-get($grid-breakpoints,md)) {
        font-size: 22px;
    }
    @media (min-width: map-get($grid-breakpoints,md)) {
        font-size: 12px;
        line-height: 20px;
    }

}