@font-face {
    font-family: 'Icon';
    src:  url('../../public/static/fonts/icons/icomoon.eot?wu9igm');
    src:  url('../../public/static/fonts/icons/icomoon.eot?wu9igm#iefix') format('embedded-opentype'),
      url('../../public/static/fonts/icons/icomoon.ttf?wu9igm') format('truetype'),
      url('../../public/static/fonts/icons/icomoon.woff?wu9igm') format('woff'),
      url('../../public/static/fonts/icons/icomoon.svg?wu9igm#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }