



// Bootstrap grid 4 variables 

$enable-grid-classes:true !default;


$xs-modern-device:350px;


$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  lg-legacy:1180px,
  xl: 1400px,
) !default;


$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  lg-legacy:1080px,
  xl: 1170px,
) !default;


$grid-columns:                12 !default;
$grid-gutter-width:           30px !default;
$grid-row-columns:            6 !default;

$grid-mobile-max: 540px;
$grid-tablet: 992px;