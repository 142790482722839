@import "base/layout";

body{
    padding-top: 56px;
    min-height: 100vh;
    @media (min-width: map-get($grid-breakpoints,sm)) {
        padding-top: 80px;
    }
    .app-base{
        min-height: 100vh;
        main{
          min-height: 75vh;
        }
    }
}

.circular_image{
  border-radius: 100%;
}

.app-link {
    font-size:10px;
    font-family: var(--font-bold);
    letter-spacing: 0.26px;
    line-height: normal;
    &.app-link--light , &.app-link--light  a {
        color:var(--theme-color-light);
    }
    &.app-link-dark, &.app-link--dark  a {
        color:var(--font-color-dark);
    }
    @media (min-width: map-get($grid-breakpoints,lg)) {
        font-size:14px;
    }
}



div{
&[data-loading="true"] {
    background: white !important;
    color:rgba(0,0,0,0.5) !important;
    user-select: none;
    cursor:wait;
    transition: background 100ms ease-in-out;

}
&[data-loading="true"]:after {
    display: inline-block;
    content:""  !important;
    width:60px;
    height:60px;
    position: relative;
    top: 100px;
    border-radius: 50%;
    vertical-align: middle;
    border:3px solid black;
    border-top-color: #b9b8b8;
    margin:auto 50%;
    transform:translateY(-50%);
    animation: spin linear 500ms infinite;
    transform-origin: center center;
    background: none  !important;
}

&[data-fullpage="true"]{
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top:0;
  left: 0;
  z-index: 6;
  background: rgba(255,255,255,0.8) !important;
  &:after{
    flex: 0 0 60px;
    top: 0;
  }
 }
}

@keyframes spin {
  0%{
      transform: rotate(-359deg);
  }
  100% {
      transform: rotate(0deg);
  }
}


.title-el-cap {
      position:relative;
      display: block;
      top:-9px;
      left:0;
      content:"";
      width:18px;
      height:18px;
      background:url(../public/static/images/title-cap.svg) no-repeat center;
      background-size: cover;
      @media (min-width: map-get($grid-breakpoints,md)) {
          top:-9.5px;
          width:19px;
          height:19px;
      }
}

.app-section {

    &[data-padding="true"] {
        padding:40px 0;
        @media (min-width: map-get($grid-breakpoints,lg)) {
            padding:100px 0;
        }
    }
    .app-section__title {
      text-align: center;
      font-size: 1.375rem;
      line-height: 1.4;
      position: relative;
        margin-bottom:40px;
        @media (min-width: map-get($grid-breakpoints,lg)) {
            font-size: 2.25rem;
            line-height: 2.625rem;
            margin-bottom:80px;
        }
    }
    .em{
      @media (min-width: map-get($grid-breakpoints,lg)) {
        &:after {
          position:absolute;
          display:inline-block;
          top:-4px;
          width:19px;
          height:19px;
          content:"";
          background:url(../public/static/images/title-cap.svg) no-repeat center;
          background-size: cover;
        }
    }
    }
    &[data-inverted="true"] .app-section__title {
      .em {
        &:after {
          background:url(../public/static/images/title-cap-inverted.svg) no-repeat center;
          background-size: cover;
        }
      }
    }
    .app-section__subtitle {
      font-size: 12px;
      letter-spacing:3.7px;
      color:var(--primary-color-green);
      font-family:var(--font-medium);
      text-transform: uppercase;
      line-height: 1.5;
      text-align: center !important;
      @media (min-width: map-get($grid-breakpoints,lg)) {
        margin-bottom:24px;
        font-size: 16px;
      }
    }
    .app-section__tagline {
      font-size:14px;
      line-height:2.14;
      letter-spacing:0.23px;
      color:#081c1b;
      text-align: center;
      @media (min-width: map-get($grid-breakpoints,lg)) {
        font-size:18px;
     }
    }
    &[data-bg="shade-1"] {
        background-color: #fbfbf8;
    }
    &[data-bg="shade-2"] {
        background-color: #f8f8f3;
    }
    &[data-bg="shade-3"] {
      background-color:var(--primary-color-green);
    }
    &[data-bg="shade-4"] {
      background-color:#121419;
    }
    &[data-inverted="true"] {
      background:var(--primary-color-green) ;
    }
}

.white_bg {
 background-color: var(--theme-color-white) !important; //#fff
}
.gray_bg {
  background-color: var(--secondary-color-gray) !important; //#f8f8f3
}
.light_bg {
  background-color: var(--theme-color-lightbg) !important; //#fafafa
}

.banner_body{
  min-height: 100vh;
  @media (max-width: 320px){
    padding-top: 130px;
  }
  @media (min-width: 321px) and (max-width: 423px){
    padding-top: 120px;
  }
  @media (min-width: 424px) and (max-width: 480px){
    padding-top: 112px;
  }
  header{
    @media (max-width: 480px){
      &[data-inverse="true"] {
        .container:not(nav, .sticky_app_banner, .category_list){
          background: var(--primary-color-green);
        }
      }
      .container:not(nav, .sticky_app_banner, .category_list){
        background-color: white;
      }
      .container:not(nav, .sticky_app_banner, .category_list){
        transition: top 0.25s linear;
        box-shadow: 1px -1px 0px #ddd;
      }
    }
    @media (max-width: 320px)
    {
      height:140px;
      .container:not(nav, .category_list){
        position: absolute;
        height: 42px;
        top: 62px;
      }
    }
    @media (min-width: 321px) and (max-width: 423px){
      height:120px;
      .container:not(nav, .category_list){
        position: absolute;
        height: 56px;
        top: 62px;
      }
    }
    @media (min-width: 424px) and (max-width: 480px){
      height:112px;
      .container:not(nav, .category_list){
        position: absolute;
        height: 56px;
        top: 56px;
      }
    }
    .sticky_app_banner{
      transition: all 0.25s linear;
    }
  }
  nav{
    top: 7.5rem;
    @media (max-width: 320px)
    {
      top: 8.875rem;
    }
    @media (min-width: 424px) and (max-width: 480px){
      top: 112px;
    }
    @media (min-width: map-get($grid-breakpoints,sm)) and (max-width: map-get($grid-breakpoints,lg)) {
      top: 5rem;
    }
    @media (min-width: 321px) and (max-width : 359px){
      top: 152px;
    }
  }
  &.hide_banner_body{
    padding-top: 56px;
    header{
      @media (max-width: 480px){
        height: 56px;
      }
      .sticky_app_banner{
        transform: translateY(-100px);
      }
      .container:not(nav, .category_list){
        @media (max-width: 480px)
        {
          height: 100%;
          position: absolute;
          top: 0;
          box-shadow: none;
        }
      }
      nav{
        top: 3.5rem;
      }
    }
  }
}

.app_render{
  padding-top: 0px;
  .hide_on_app_render{
      display: none;
  }
  .app_render_botton{
    margin-bottom: 3rem;
  }
}

.hidden-input{
  display: none;
}