@import '../../styles/base/layout';


.container{
    background-color: white;
    padding-left: 9px !important;
    padding-right: 7.5px !important;
    height: auto !important;
    position: unset !important;
    .sticky_app_banner{
        display: flex;
        padding: 6px 6px 6px 6px;
        .banner_close{
            margin: auto 0px;
        }
        .banner_logo{
            margin: auto 0px;
            @media (max-width: 480px){
                padding-left: 12px;
            }
            @media (max-width: 320px)
            {
                padding-left: 7.5px;
            }
            
        }
        .banner_info
        {
            @media (max-width: 480px){
                padding-left: 12px;
            }
            @media (max-width: 320px)
            {
                padding-left: 7px;
            }
            h6{
                font-size: 16px;
                font-family: var(--font-semi-bold);
                color: black;
            }
            p{
                font-family: var(--font-regular);
                padding-top: 6px;
                font-size: 12px;
                color: #3D3D3D;
                line-height: 14px;
                @media (min-width: 424px)
                {
                    width: 200px !important;
                }
                @media (max-width: 320px)
                {
                    width: 130px;
                }
                @media (min-width: 321px)
                {
                    width: 152px;
                }
                
            }
        }
        .banner_action{
            padding-left: 10px;
            margin: auto 0px;
            a{
                background-color: var(--primary-color-orange);
                border-radius: 100px;
                font-size: 14px;
                font-family: var(--font-semi-bold);
                text-decoration: none;
                color: black;
                @media (max-width: 480px)
                {
                    padding: 8px 22px !important;
                    white-space: nowrap;
                }
                @media (max-width: 320px)
                {
                    padding: 6px 15px !important;
                    white-space: nowrap;
                }
            }
        }
    }
    @media (min-width: 540px) {
        display: none;
    }
}

.download_container{
    @media (min-width: map-get($grid-breakpoints,lg)) {
        display: none;
    }
    h6{
        font-size: 16px;
        line-height: 16px;
        font-family: var(--font-semi-bold);
        color: #1C1C1C;
        text-align: center;
        margin-top: 35px;
    }
    .download_banner{
        margin: 15px 0px 35px 0px;
        display: flex;
        justify-content: center;
    }
}


.interactive_banner_container{
    background: linear-gradient(180deg, #006471 0%, #3D1F6C 133%);
    max-width: 100%;
    .left_section{
        margin-top: 70px;
        h1{
            font-size: 48px;
            color: white;
            line-height: 1;
            font-family: var(--font-semi-bold);
        }
        .points{
            display: flex;
            margin-top: 18px;
            p{
                color: white;
                font-size: 16px;
                padding-left: 8px;
                font-family: var(--font-semi-bold);
                margin: auto 0px;
            }
        }
        .input_section{
            margin-top: 54px;
            p{
                color: white;
                font-size: 16px;
                margin: auto 0px;
                font-family: var(--font-medium);
                padding-bottom: 10px;
            }
            .input_line{
                display: flex;
                .input_box{
                    width: 426px;
                    border-top-right-radius: 0px;
                    border-bottom-right-radius: 0px;
                    &::before{
                        content: "+91 | ";
                        color: #686A79;
                        font-size: 14px;
                        padding-left: 5px;
                        font-family: var(--font-regular);
                        position: absolute;
                        margin-top: 17px;
                        margin-left: 10px;
                    }
                    input{
                        padding-left: 50px;
                        color: #3E4054;
                        font-family: var(--font-regular);
                        border: none;
                        border-top-right-radius: 0px;
                        border-bottom-right-radius: 0px;
                        &::placeholder{
                            color: #686A79;
                            font-size: 14px;
                        }
                    }
                }
                a{
                    padding: 17px 28px;
                    background: #15939A;
                    border-radius: 0px 4px 4px 0px;
                    font-size: 14px;
                    color: white;
                    margin: auto 0px;
                    font-family: var(--font-bold);
                    cursor: pointer;
                }
                .disable_button{
                    opacity: 0.7;
                    cursor:default;
                    user-select: none;
                }
            }
        }
        .success_toast{
            display: flex;
            background: #DCFEE0;
            border: 1px solid #4DBD58;
            border-radius: 4px;
            width: 570px;
            height: 48px;
            margin-top: 10px;
            // padding: 11px;
            .image{
                width: 22px;
                height: 22px;
                margin: auto 0px auto 20px;
            }
            p{
                font-size: 14px;
                font-family: var(--font-semi-bold);
                color: #4DBD58;
                padding: 15px 15px 15px 10px;
            }
        } 
        .error_toast{
            display: flex;
            background: #E9F6FF;
            border: 1px solid #008CF0;
            border-radius: 4px;
            width: 570px;
            height: 48px;
            margin-top: 10px;
            // padding: 11px;
            .image{
                width: 22px;
                height: 22px;
                margin: auto 0px auto 20px;
            }
            p{
                font-size: 14px;
                font-family: var(--font-semi-bold);
                color: #008CF0;
                padding: 15px 15px 15px 10px;
            }
        } 
        .download_store{
            h6{
                font-size: 14px;
                line-height: 17px;
                font-family: var(--font-bold);
                color: #FFC20E;
                text-align: center;
                margin-top: 35px;
                font-style: italic;
            }
            .download_banner{
                margin: 15px 0px 35px 0px;
                display: flex;
                justify-content: center;
                a{
                    padding-right: 10px;
                }
            }
        }
    }

    .right_section{
        padding-top: 10px;
        position: relative;
        div{
            position:initial !important;
        }
        .image{
            top: 0px !important;
        }
    }
}

.mobile_image{
    background-color: #121419;
    display: block;
}

.interative_banner_holder{
    .interactive_banner_container{
        display: none;
    }
    @media (min-width: map-get($grid-breakpoints,lg)) {
        .interactive_banner_container{
            display: block;
        }
        .mobile_image{
            display: none;
        }
    }
}