
@font-face {
    font-family: 'Gilroy Bold';
    src: url('../../public/static/fonts/gilroy-bold.woff2') format('woff2'),
         url('../../public/static/fonts/gilroy-bold.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;

}




@font-face {
    font-family: 'Gilroy Medium';
    src: url('../../public/static/fonts/gilroy-medium.woff2') format('woff2'),
         url('../../public/static/fonts/gilroy-medium.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;

}




@font-face {
    font-family: 'Gilroy Regular';
    src: url('../../public/static/fonts/gilroy-regular.woff2') format('woff2'),
         url('../../public/static/fonts/gilroy-regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;

}




@font-face {
    font-family: 'Gilroy SemiBold';
    src: url('../../public/static/fonts/gilroy-semibold.woff2') format('woff2'),
         url('../../public/static/fonts/gilroy-semibold.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;

}
@font-face {
    font-family: 'Montserrat';
    src: url('../../public/static/fonts/Montserrat-Regular.ttf') format('ttf');
    font-weight: normal;
    font-style: normal;
    font-display: swap;

}
@font-face {
    font-family: 'Montserrat Regular';
    src: url('../../public/static/fonts/Montserrat-Medium.ttf') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;

}