@import "./base/layout";
@import "./utilities/utilities";
// @import "../styles/utilities/aos";

.query-modal {
    padding-top: 32px;
    height: 500px;
    overflow-y: hidden;
    overflow-x: hidden;
    @media (min-width: map-get($grid-breakpoints , sm )) {
        padding-top: 32px;
        height: auto;
    }
    .query-modal__title {
        font-size: 14px;
        line-height: 1.71;
        color: #111c24;
        font-family: var(--font-bold);
        margin-bottom: 15px;
        @media (min-width: map-get($grid-breakpoints , sm )) {
            font-size: 28px;
            line-height: 1.43;
            margin-bottom: 56px;
            padding-right:50px;
        }
    }
    .query-modal__list {
        overflow: auto;
        height: 350px;
        @media (min-width: map-get($grid-breakpoints , sm )) {
            display: flex;
            flex-wrap: wrap;
            height: auto;
        }
    }
    .query-modal__list_item {
        display: flex;
        flex-direction: column;
        font-size: 14px;
        font-family: var(--font-regular);
        letter-spacing: 0.18px;
        color: #000;
        line-height: 1.4;
        padding: 17px 16px;
        border: solid 1px #cecece;
        border-radius: var(--border-radius-sm);
        transition: all 300ms ease-in;
        cursor: pointer;
        display: flex;
        flex-wrap: wrap;

        &:hover {
            background: rgba(254, 220, 137, 0.1);
            border-color: #ffe090;
        }
        &.selected {
            background: rgba(254, 220, 137, 0.4);
            border-color: #fdb913;
        }
        &.not_covered{
            background: rgba(255, 255, 255,1);
            pointer-events: none;
            z-index: 0;
            border: solid 1px #cecece;
            border-radius: var(--border-radius-sm);
            margin-bottom: 3rem;
            &:hover {
                background: rgba(254, 220, 137, 0.1);
                border-color: #ffe090;
            }
            color: rgba(100, 93, 93, 0.6);
        }
        & + li {
            margin-top: 24px;
            position: relative;
        }

        @media (min-width: map-get($grid-breakpoints, sm))  {
            width: calc(50% - 12px);
            flex: 0 0 calc(50% - 12px);
            max-width: calc(50% - 12px);
            font-size:16px;
            font-family: var(--font-medium);
            line-height: 1.4;
            letter-spacing: 0.25px;
            border-color: #575f65;
            padding: 16px 24px;
            min-height: 90px;
            display: inline-flex;
            // align-items: center;
            justify-content: flex-start;
            margin-bottom: 3rem;
            &:nth-child(odd) {
                margin-right: 12px;
            }
            &:nth-child(even) {
                margin-left: 12px;
            }
            & + li {
                margin-top: 0;
            }
        }
        @media (min-width: map-get($grid-breakpoints, md)) {
            font-size: 16px;
            height: auto;
        }
        @media (min-width: map-get($grid-breakpoints , lg )) {
        //    font-size:20px;
           font-size:18px;
           height: auto;
           margin-bottom: 3rem;
        }
        span{
            padding-top: 6px;
            color: #FDA000;
            font-family: var(--font-semi-bold);
            font-size: 12px;
            line-height: 14px;
            position: absolute;
            bottom: -24px;
            left: 0;
            @media (min-width: map-get($grid-breakpoints, lg)) {
                font-size: 12px;
            }
            .icon{
                vertical-align: middle;
                margin-right: 6px;
                font-size: 15px;
                word-wrap: normal;
            }
        }

    }
    
    .query-modal__link {
        display: block;
        margin-top: 24px;
        color: #000;
        letter-spacing: 0.23px;
        font-family: var(--font-medium);
        font-size: 14px;
        @media (min-width: map-get($grid-breakpoints , sm )) {
            margin-top: 0;
            font-size: 16px;
        }
    }
    .query-modal__action {
        padding: 16px 32px;
        // margin-top: 44px;
        margin-top: 10px;
        text-align: right;
        // box-shadow: 2px 0 4px 0 #00000019;
        margin-left:-20px;
        margin-right: -20px;
        & > a {
            padding-left: 33px;
            padding-right: 33px;
        }
        @media (min-width: map-get($grid-breakpoints , sm )) {
            margin-top: 44px;
            box-shadow: none;
            margin-left:0;
            margin-right:0;
            & > a:after {
                display: inline-block;
                font-family: "Icon";
                content:"\e901";
                font-size:12px;
                color:#000;
                margin-left:16px;
                vertical-align: middle;
                
            }
        }
    }
}

.testimonials {
    .slider-wrap {
        padding-top: 40px;
        .center {
            text-align: center;
        }
        .slick-slide {
            width:100%;
            opacity: 0.2;
            padding: 0 40px;
            transform-origin: center center;
        }
        .slick-track {
            padding: 40px 0;
            @media (min-width: map-get($grid-breakpoints , lg )) {
                padding: 60px 0;
            }
        }
        .slick-current {
            opacity:1;
            transition:all 400ms linear 100ms;
            transform:scale(1.3);
            @media (min-width: map-get($grid-breakpoints , lg ) ) {
            transform:scale(1.4);
            }
        }
    }
    .testimonials-content {
        @media (min-width: map-get($grid-breakpoints , lg ) )  {
            margin-top:-30px;
        }
        .slick-active {
            .see-more{
                z-index: 10;
                position: relative;
            }
        }
    }
    &.thumbnail-manual {
        @media (min-width: map-get($grid-breakpoints , md ) ) {
            .thumbnail-slider {
            
                // .slick-active.slick-center.slick-current + .slick-slide.slick-active    {
                //     //border:1px solid gray;
                //     transform: scale(0.9);
                // }
                .slick-active.slick-center.slick-current + .slick-slide.slick-active +  .slick-slide.slick-active {
                  //border:1px solid red;
                  transform: scale(0.7);
                }
                .slick-active.slick-center.slick-current + .slick-slide.slick-active +  .slick-slide.slick-active + .slick-slide.slick-active {
                    //border:1px solid red;
                    transform: scale(0.5);
                }
                .slick-active.slick-center.slick-current + .slick-slide.slick-active +  .slick-slide.slick-active + .slick-slide[aria-hidden="true"] {
                    
                    transform: scale(0.3);
                  }
                .slick-slide[aria-hidden="true"] + .slick-slide[aria-hidden="false"]   {
                      transform: scale(0.7);
                  }
                  .slick-slide[aria-hidden="true"] + .slick-slide[aria-hidden="false"]   {
                    transform: scale(0.7);
                }
                .testimonial-item {
                    height:0;
                    padding-bottom:100%;
                }
                .testimonial-thumb {
                    position:absolute;
                    width:100%;
                    height:100%;

                }
                  
            //       .slick-slide[aria-hidden="true"] + .slick-slide[aria-hidden="false"] +  .slick-slide[aria-hidden="false"]    {
            //           //border:1px solid rgb(0, 217, 255);
            //           transform: scale(0.9);
            //   }
              }
        }
    }
    &.thumbnail-auto:not(.disable-thumb) {
        @media (min-width: map-get($grid-breakpoints , md ) ) {
            .thumbnail-slider {

                .slick-active.slick-center.slick-current {
                    transform: scale(1);
                    opacity:1;
                }
                .slick-slider:not(.slick-current) {
                    opacity:0;
                }
                .slick-active.slick-center.slick-current + .slick-slide.slick-active {
                  
                  transform: scale(0.7);
                }
                .slick-active.slick-center.slick-current + .slick-slide.slick-active +  .slick-slide.slick-active + .slick-slide[aria-hidden="true"] {
                    
                    transform: scale(0.3);
                }
                .slick-slide[aria-hidden="true"] + .slick-slide[aria-hidden="false"]   {
                      
                      transform: scale(0.7);
                }
                .slick-slide {
                    width:100%;
                    // height: 192px;
                }
               
              }    
        }
    }
    &.disable-thumb {
        .thumbnail-slider {
            .slick-active.slick-center.slick-current {
                transform: scale(1);
                opacity:1;
            }
        }
    }
    .container{
        h2{
            margin-bottom: 18px;
        }
        h3{
            margin-bottom: 15px;
        }
    }
}

.category-list {
    .slick-track{
        @media (min-width: map-get($grid-breakpoints, lg)){
            padding-top: 25px;
            padding-bottom: 25px;
        }
    }
    .slider-wrap {
        .slick-slide {
            & > div:first-child {
                margin-bottom: 58px;
            }
            @media (min-width: map-get($grid-breakpoints, lg)){
                padding-left: 12px;
            }
        }
        .slick-dots {
            text-align:center;
            padding-top:40px;
            padding-bottom:40px;
            li {
                display:inline-block;
                overflow:hidden;
                width:10px;
                height:4px;
                text-indent:-1000%;
                white-space:nowrap;
                background:#535454;
                opacity:0.4;
                border-radius:2.5px;
                button {
                    display: block;
                    width: 100%;
                    background: transparent;
                    border: none;
                    height: 100%;
                    cursor: pointer;
                    overflow: hidden;
                    text-indent: -1000%;
                    white-space: nowrap;
                    outline: none;
                }
                &.slick-active {
                background:#fdb913;
                opacity:1;
                }
                & + li {
                    margin-left:10px;
                }
            }
            @media (min-width: map-get($grid-breakpoints, lg)){
                padding-bottom: 0;
                padding-top: 25px;
                li {
                    width: 20px;
                    height:4px;
                    border-radius:2px;
                }
            }
        }
    }
}
.partners, .generic-slider{
    h4{
        @media (min-width: map-get($grid-breakpoints, lg)){
            margin-bottom: 0px !important;
        }
        margin-bottom: 30px !important;
    }
    .slick-track{
        @media (min-width: map-get($grid-breakpoints, lg)){
            padding-top: 25px;
            padding-bottom: 25px;
        }
    }
    .slick-slide {
        // & > div:first-child {
        //     // margin-bottom: 58px;
        // }
        @media (min-width: map-get($grid-breakpoints, lg)){
            padding-left: 12px;
        }
    }
    .slick-dots {
        text-align:center;
        padding-top:40px;
        padding-bottom:40px;
        li {
            display:inline-block;
            overflow:hidden;
            width:10px;
            height:4px;
            text-indent:-1000%;
            white-space:nowrap;
            background:#535454;
            opacity:0.4;
            border-radius:2.5px;
            button {
                display: block;
                width: 100%;
                background: transparent;
                border: none;
                height: 100%;
                cursor: pointer;
                overflow: hidden;
                text-indent: -1000%;
                white-space: nowrap;
                outline: none;
            }
            &.slick-active {
            background:#fdb913;
            opacity:1;
            }
            & + li {
                margin-left:10px;
            }
        }
        @media (min-width: map-get($grid-breakpoints, lg)){
            padding-bottom: 0;
            padding-top: 25px;
            li {
                width: 20px;
                height:4px;
                border-radius:2px;
            }
        }
    }
}
.device-list-tab,.tab-list {
    .slider-wrap {
        .slick-slider {
            .slick-slide + .slick-slide .app-list-carosel__item a:before {
                position: absolute;
                top: 0;
                left: 0;
                content: "";
                width: 1px;
                height: 100%;
                background: #979797;
                opacity: 0.1;
            }
            .slick-track {
                .slick-slide {
                    &:first-child .app-list-carosel__item a {
                        border-top-left-radius: 10px;
                    }
                    &:last-child .app-list-carosel__item a {
                        border-top-right-radius: 10px;
                    }
                }
            }
            .app-list-carosel__item--selected a {
                background: var(--primary-color-orange);
                color: var(--font-color-dark);
                font-family: var(--font-bold);
                &:hover,
                &:focus {
                    background: var(--primary-color-orange);
                }
            }
        }
    }
}
.tab-list{
    margin-top: 50px;
    @media (min-width: map-get($grid-breakpoints , lg ) ) {
    margin-left: 22.8%;
    }
}

.partners, .generic-slider {
    background-color: white !important;
    position:relative;
    .container{
        position:relative;
        h2{
            margin-bottom: 0px;
        }
    }
    h3{
        text-align: left;
        font-size: 12px;
        margin-bottom: 20px !important;
        line-height: 1.5;
    }
    @media(min-width:map-get($grid-breakpoints , lg )){
        h3{
            margin-bottom: 35px !important;
        }
        .slick-prev{
            left:0rem;
        }
        .slick-next{
            right:0rem;
        }
    }
    .slick-arrow{
        position:absolute;
        top:50%;
        height:25px;
        width:25px;
        @media(min-width:map-get($grid-breakpoints , lg ))
        {
            height:40px;
            width:40px;
        }

    }
}

.generic-slider{
    .slider-container{
        padding-top: 24px;
    }
    .container
    {
        h3{
            margin-bottom: 16px !important;
        }
        .app-section__tagline{
            color: #9394A0;
        }
    }
    img{
        border-radius: 2px;
    }
    h6{
        padding-top: 8px;
        font-size: 14px;
        font-family: var(--font-semi-bold);
        @media(min-width:map-get($grid-breakpoints , lg ))
        {
            font-size: 18px;
            padding-top: 14px;
        }
    }
    .offer_text{
        padding-top: 4px;
        font-family: var(--font-regular);
        color: #565F65;
        font-size: 12px;
        padding-right: 20px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        @media(min-width:map-get($grid-breakpoints , lg ))
        {
            font-size: 16px;
        }
    }
    .slick-disabled {
        display: none !important;
    }
    .slick-slider{
        .slick-track{
            padding-bottom: 0px;
        }
        .slick-prev{
            left: -24px;
            top: 51%;
            opacity: 0;
            &:before  {
                position: absolute;
                display: inline-block;
                font-size:7px;
                left: 50%;
                top:50%;
                content: "";
                color: #575f65;
                background-image: url('../public/static/images/home/arrow-right.png');
                background-repeat: no-repeat;
                background-position: center center;
                background-size: contain;
                width: 20px;
                height: 20px;
                transform: translate(-50%,-50%) rotate(-180deg);

            }
        }
        .slick-next{
            opacity: 0;
            top: 51%;
            right: -38px;
            &:before  {
                position: absolute;
                display: inline-block;
                font-size:7px;
                left: 29%;
                top:29%;
                transform: none;
                content: "";
                color: #575f65;
                background-image: url('../public/static/images/home/arrow-right.png');
                background-repeat: no-repeat;
                background-position: center center;
                background-size: contain;
                width: 20px;
                height: 20px;
            }
        }
        .slick-dots{
            padding-top: 0px;
            padding-bottom: 0px;
        }
    }
    &:hover{
        .slick-slider{
            .slick-next, .slick-prev{
                opacity: 1;
            }
        }
    }
}

.awards {
    position:relative;
    .container{
        position:relative;
    }
  
    @media(min-width:map-get($grid-breakpoints , lg )){
        h4 {
            text-align:left !important;
            margin-bottom:20px !important;
        }
    .slick-prev{
        left:0;
        transform: translateX(100%);
    }
    .slick-next{
        right:0;
        transform: translateX(-100%);
        }
    }
     .slick-arrow{
        position:absolute;
        height:20px;
        width:20px;
        @media(min-width:map-get($grid-breakpoints , lg ))
        {
            height:30px;
            width:30px;
        }
    }
}

.team {
    position:relative;
    .container{
        position:relative;
        .row{
        .slick-slider{
            a{
                display: initial !important;
                margin-left: 70px;
                margin-right: 70px;
                top:77.5%;
                width: 20px;
                height: 20px;
                @media (min-width: map-get($grid-breakpoints , md )) {
                    margin-left: 0px;
                    margin-right: 0px;
                    width: 40px;
                height: 40px;
                top:63%;

                }
            
            }
        }
    }
}
   
}

.thankyou-modal {
    div{
        div{

            @media (min-width:map-get($grid-breakpoints , lg )) {
                min-height:50% !important;
            }
            @media (min-width:768px) {
                min-height:25% !important;
            }
            div{
                    height:150px;
                    font-family:var(--font-medium);
                    text-align:center;
                    h2{
                        @media(min-width: map-get($grid-breakpoints , lg )) {
                            padding-top:50px;
                        }

                    }
                    p{
                        font-family:var(--font-regular);
                        text-align:justify;
                        font-size:15px;
                        padding-top:30px;
                        padding-bottom:30px;
                        line-height:1.5;
                    }
                    button{
                        border-radius: 25px;
                        height:50px;
                        background-color: #fdb913;
                        color: #1c1c1c;
                        background: #eaad00;
                    }
            }
        }
    }
}




.about-us {
    @media(min-width:map-get($grid-breakpoints , lg ))
    {
        h3{
        width:754px;
        font-size: 56px;
        line-height: 1;
        }
        p{
        font-size: 20px;
        width: 715px;
        font-family:var(--font-medium);
        line-height:1.4;
        }
        h3,p{
        margin-left:auto;
        margin-right:auto;
        }
    }
}



.faq {

    &.faq-primary:not(#a) {

        .faq-wrap {
            border: solid 1px #dbdbdb;
            border-radius: 10px;
        }

        .MuiPaper-root{
            background: transparent;

            .accordion-title   {
                font-family: var(--font-medium);
                @media(min-width:map-get($grid-breakpoints , lg )) {
                    font-size: 18px;
                    line-height: 1.75;
                }
            }
            .accordion-content {
                padding: 16px;
                font-size: 14px;
                padding-right:50px;
                line-height: 1.5;
                background: #fff;
                span {
                    color:#000;
                }
                @media(min-width:map-get($grid-breakpoints , lg )) {
                    font-size:16px;
                    padding:41px;
                    padding-top:32px;
                    line-height: 1.6;
                    padding-right: 140px;
                }
            }
            .accordion-arrow  {
                display: inline-block;
                width: 16px;
                height: 16px;
                border-radius: 50%;
                background: var(--secondary-color-green);
                text-align: center;
                &:before {
                    display:block;
                    font-size: 7px;
                    content:"\e90c";
                    color:#fff;
                    line-height:16px;
                    font-weight: bold;
                }
                @media(min-width:map-get($grid-breakpoints , lg )) {
                    width:24px;
                    height: 24px;
                    &:before {
                        font-size:12px;
                        line-height: 24px;
                    }
                }
            }
           &:not(:first-child) {
                border-radius:0;
           }
           & + .MuiPaper-root {
                border-top: solid 1px #dbdbdb;
           }
           &:first-child {
               border-top-left-radius:10px;
               border-top-right-radius:10px;
               border-bottom-left-radius:0;
               border-bottom-right-radius:0;
           }
           &:last-child {
               border-bottom-left-radius:10px;
               border-bottom-right-radius:10px;
           }
            &.Mui-expanded {
                margin-bottom:0;
                background-color: var(--secondary-color-green);
                // border-bottom-left-radius: 0;
                // border-bottom-right-radius: 0;
                // .MuiButtonBase-root {
                //     padding-bottom:0;
                // }
                .accordion-title , .accordion-content  {
                    color:#fff;

                }
                .accordion-title {
                    font-family: var(--font-bold);
                }
                // & ~ .MuiPaper-root {
                //    border-radius:0;
                // }
                .accordion-arrow {
                    background: #fff;
                    &:before {
                        color:var(--secondary-color-green);
                        content:"\e90a";
                    }
                }
            }
            & + .MuiPaper-root {
                margin-top:0;
            }
        }
    }
}


.service-we-offer {
    .slick-slider{
        .slick-current {
            z-index:2;
        }
        .slick-slide {
            opacity:0;
        }
        .slick-slide.slick-current {
            opacity:1;
        }
        &.dropdown-open {
            .slick-list {
                overflow:visible;
            }
        }
        
    }
    .dropdown[data-open="true"] {
        .dropdown__menu {
            width:300px;
            left:0;
            @media(min-width:map-get($grid-breakpoints , lg )) {
                width:calc(100% + 100px);
            }
        }
    }
}

@mixin o-icon {
    position: absolute;
    right:-95px;
    top:-62px;
    display: inline-block;
    content:"";
    width:263px;
    height:318px;
    background: url(../public/static/images/o-brand.svg) no-repeat center;
    background-size: cover;
    z-index:-1;
    opacity:0.6;
}


.features-homepage {
    .container:nth-child(2) {
        @media(min-width:map-get($grid-breakpoints , lg )) { 
            position: relative;
            border-radius: 24px;
            border: solid 1px #e8e8e8;
            padding:60px 50px;
            background:#fff;
            &:after {
               @include o-icon;
            }
        }
    }
}

.compare-plans {
    
    .container:nth-child(2) {
        position: relative;
        z-index: 0;
        &:after {
          @include o-icon;
          z-index:-1;
        }
    }
}

.cross-sell {

    .slick-dots {
        text-align:center;
        padding-top:40px;
        padding-bottom:5px;
        li {
            display:inline-block;
            overflow:hidden;
            width:30px;
            height:7px;
            text-indent:-1000%;
            white-space:nowrap;
            background:#081c1b;
            opacity:0.4;
            border-radius:3.5px;
            button {
                display: block;
                width: 100%;
                background: transparent;
                border: none;
                height: 100%;
                cursor: pointer;
                overflow: hidden;
                text-indent: -1000%;
                white-space: nowrap;
            }
            &.slick-active {
            background:#fdb913;
            opacity:1;
            }
            & + li {
                margin-left:10px;
            }
        }
    }

    .slick-arrow{
        position:absolute;
        top:35% !important;
        height:25px;
        width:25px;
        &:before  {
            color: var(--primary-color-green) !important;
        }
        @media(min-width:map-get($grid-breakpoints , lg ))
        {
            height:40px;
            width:40px;
        }

    }
    .slick-prev{
        left: 70px !important;
        @media(max-width:map-get($grid-breakpoints , lg ))
        {
           left: 60px !important;
        }
    }

   
    .slick-next{
        right: 70px !important;
        @media(max-width:map-get($grid-breakpoints , lg ))
        {
            
            right: 60px !important;
        }
    }
}

.processwork{
    padding: 0px !important;
    padding-top: 60px !important;
    h3 {
        margin-bottom: 4px !important;
    }
    @media(max-width:map-get($grid-breakpoints , lg )) {
        padding-top: 40px !important;
        padding-bottom: 16px !important;
        h3 {
            margin-bottom: 20px !important;
        }
    }
}

.error-no-found {
    text-align: center;
    .level-two {
        margin-bottom:32px;
    }
    .level-one {
        margin:16px 0;
        h3{
            margin-bottom: 0;
        }
    }
    p {
        line-height: 1.4;
    }
    .row {
        align-items: center;
        & > div:last-child {
            order:-1;
        }
    }
    img {
        display: block;
        width:100%;
        height:auto;
    }
    @media(min-width:map-get($grid-breakpoints , lg )) {
        .level-two {
            margin-bottom:0;
        }
        .row {
            & > div:last-child {
                order:0;
            }
        }
    }
}

.odd-even-start-with-white{
    > section:nth-of-type(odd){
        background-color: white;
        section {
            background-color: white !important;
        }
    }
    > section:nth-of-type(even){
        background-color: #f8f8f2;
        section {
            background-color: #f8f8f3 !important;
        }
    }
}

.odd-even-start-with-gray{
    > section:nth-of-type(odd){
        background-color: #f8f8f2;
        section {
            background-color: #f8f8f3 !important;
        }
    }
    > section:nth-of-type(even){
        background-color: white;
        section {
            background-color: white !important;
        }
    }
}
.contact-us{
    div{
        h1{
            font-size: 32px;
            text-align: center;
            @media(min-width:map-get($grid-breakpoints , lg )) {
                font-size: 52px;
            }
        }
    }
}

.react-calendar {
    width: 266px !important;
    box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.07);
    border: 0px !important;
    border-top: 1px solid black;
    position: absolute;
    z-index: 500;
    max-width: 266px !important;
    margin-top: 5px;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.20);
    @media (max-width: map-get($grid-breakpoints , sm )) {
        max-width: 100%;
    }
    @media (min-width: map-get($grid-breakpoints , md )) {
        margin-left: 95px;
    }

        right:0;
        @media (min-width: map-get($grid-breakpoints , lg )) {
            right:33px;
            left:-80px;
        }
       

    .react-calendar__tile:enabled:hover {
        background-color: rgba(251, 183, 19, 0.5);
        color: white;
        border-radius: 75%;
    }
    .react-calendar__tile .react-calendar__year-view__months__month:enabled:hover {
        background-color: rgba(251, 183, 19, 0.5);
        color: white;
        border-radius: 0%;
    }
    .react-calendar__month-view__days__day--weekend {
        color: #3f3f3f;
    }
    .react-calendar__month-view__days__day--weekend:disabled abbr{
        color: #1010104D;
    }
    .react-calendar__month-view__days__day:disabled abbr{
        color: #1010104D;
    }
    .react-calendar__tile {
        font-family: var(--font-bold);
        font-size: 12px;
    }
    .react-calendar__month-view__weekdays__weekday {
        color: #919191;
        font-size: 12px;
    }
    .react-calendar__month-view__weekdays {
        text-transform: capitalize;
        text-decoration: none;
        width: 90%;
        margin: auto;
    }
    .react-calendar__navigation__label {
        order: -1;
        text-align: center;
        font-size: 12px;
        font-family: var(--font-bold);
    }
    .react-calendar__month-view__days {
        width: 90%;
        margin: auto;
    }
    .react-calendar__navigation {
        margin-bottom: 0px;
        height: 30px;
        margin: auto;
        margin-right: 10px;
    }
    .react-calendar__tile--now {
        text-decoration: underline;
  
    }
    .react-calendar__tile--active {
        background: #fbb713;
        border-radius: 75%;
        color: white;
        text-decoration: none;
    }
    .react-calendar__tile--active:enabled:hover,
    .react-calendar__tile--active:enabled:focus {
        background: #fbb713;
    }
    .react-calendar__tile .react-calendar__month-view__days__day {
        color: #3f3f3f;
    }
    input {
        width: 90%;
        border-bottom: 1px solid yellow;
        border-top: none;
        border-right: none;
        border-left: none;
        margin-left: 10px;
        padding-bottom: 14px;
        padding-top: 16px;
        font-size: 12px;
        font-family: var(--font-regular);
    }
    input:focus {
        outline: none;
    }
    a {
        position: absolute;
        font-size: 12px;
        margin-left: -16px;
        font-family: var(--font-bold);
        padding-bottom: 14px;
        padding-top: 16px;
    }
    .hide {
        display: none;
    }
    .react-calendar__month-view__weekdays__weekday abbr {
        text-decoration: none;
    }
    .react-calendar__navigation__arrow {
        min-width: 28px;
        background: none;
        transform: rotate(90deg);
        padding: 0px 0px 4px 0px;
        font-size: 20px;
        color: #939393;
    }
    button.react-calendar__tile.react-calendar__tile--active.react-calendar__year-view__months__month:enabled:hover,
    button.react-calendar__tile.react-calendar__tile--active.react-calendar__year-view__months__month:enabled:hover {
        border-radius: 0%;
        background: #fbb713;
        color: white;
    }
    button.react-calendar__tile.react-calendar__tile.react-calendar__century-view__decades__decade:enabled:hover,
    button.react-calendar__tile.react-calendar__decade-view__years__year:enabled:hover,
    button.react-calendar__tile.react-calendar__tile.react-calendar__year-view__months__month:enabled:hover {
        border-radius: 0%;
        color: white;
        background-color: rgba(251, 183, 19, 0.5);
    }

    button.react-calendar__tile.react-calendar__tile--hasActive.react-calendar__century-view__decades__decade,
    button.react-calendar__tile.react-calendar__tile--hasActive.react-calendar__decade-view__years__year,
    button.react-calendar__tile.react-calendar__tile--hasActive.react-calendar__year-view__months__month {
        border-radius: 0%;
        background: #fbb713;
        color: white;
    }
}

.slik{
    color: black;
    .container{
        .slider-wrap{
            .slick-slider{
                    .slick-list{
                    .slick-track{
    @media(min-width:map-get($grid-breakpoints , lg )) {

                        width: 310px !important;
    }
                    //   margin-right: 0px;
                        .slick-slide{
                           
                           .tab_item_list a {
                                border-radius:0;
                                font-family: var(--font-bold);
                            }
                            .tab_item_list {
                                border-radius: 0;
                                &[data-selected="false"] {
                                    background:#efefef;
                                }
                            }
    @media(min-width:map-get($grid-breakpoints , lg )) {

                            width: 150px !important;
                            display: inline-block;
                            float:none;
    }
                        }
                }
            }
        }
    }
}
}

.hidden__tabs{
    display: none;
}

.para,.para__removearrow{
    div{
    .font-weight-bold{
        font-weight: bold;
    }
}
    ul {
        li{
            a{
                color: black !important;
            }
        }
    }
    p {
        a{
            color: black !important;
        }
        
        .p_heading{
            @media (min-width: map-get($grid-breakpoints,lg)) {
                font-size: 25px;
            }
            @media (max-width: map-get($grid-breakpoints,md)) {
            display: none;
            }
            font-size: 13px;
            font-family: var(--font-bold);
        }
        padding-top: 10px;
        line-height: 1.5;
    }
    div{
        .table_bottom{
        overflow-x: scroll;
        }
        p {
            .p_heading{
                @media (min-width: map-get($grid-breakpoints,lg)) {
                    font-size: 25px;
                }
                @media (max-width: map-get($grid-breakpoints,md)) {
                display: none;
                }
                font-size: 13px;
                font-family: var(--font-bold);
            }
            padding-top: 10px;
            line-height: 1.5;
        }
        ul{
            list-style:inside;
            li{
                word-spacing: 0px !important;
                text-align: inherit !important;
                @media (max-width: map-get($grid-breakpoints,md)) {
                    font-size: 13px !important;
                }
                font-size: 16px !important;
                padding-bottom: 15px;
                 ul {
                     padding-top: 10px;
                    list-style: circle;
                    padding-left: 30px;
                    font-size: 13px;
                 }
            }
        }
    }
    ul{
        list-style:disc;
        li{
            word-spacing: 0px !important;
            text-align: inherit !important;
            @media (max-width: map-get($grid-breakpoints,md)) {
                font-size: 13px !important;
            }
            font-size: 16px !important;
            padding-bottom: 15px;
             ul {
                 padding-top: 10px;
                list-style: circle;
                padding-left: 30px;
                @media (max-width: map-get($grid-breakpoints,md)) {
                    font-size: 13px !important;
                }
                font-size: 16px !important;
             }
        }
    }
}

.para__removearrow{
    .accordion-arrow{
        display: none;
    }
}

.map, .contact_map{
    height: 100%;
    &.full-screened-map{
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
        max-width: 100%;
        z-index: 1000;
        .full-screen-trigger{
            box-shadow: 0px 0px 2px 0px #000;
            border-radius: 50%;
            font-size: 20px;
            font-weight: bold;
        }
    }
    .gmnoprint,.gm-style-iw, .gm-style-iw-c,.gm-fullscreen-control{
        display: none !important;
    }
    .gm-ui-hover-effect{
        display: none !important;
    }
    .gm-fullscreen-control{
        display: none !important;
    }
    .full-screen-trigger{
        background: none rgb(255, 255, 255);
        border: 0px;
        margin: 10px;
        padding: 0px;
        text-transform: none;
        appearance: none;
        position: absolute;
        cursor: pointer;
        user-select: none;
        border-radius: 2px;
        height: 40px;
        width: 40px;
        box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
        overflow: hidden;
        bottom: 0px;
        right: 15px;
        > img{
            height: 18px;
            width: 18px;
            vertical-align: bottom;
        }
    }
    .close-icon{
        top: 0px
    }
    .popup-info{
        position: absolute;
        width: 50px;
        height: 50px;
        top: 50%;
        left: 50%;
        margin-left: -25px;
        margin-top: -25px;
        // transform: translate(-50%, -65%);
        .map_desc{
            text-align: left;
            position: absolute;
            top: auto;
            bottom: 125%;
            left: 50%;
            min-width: 280px;
            margin-left: -140px;
            padding: 8px 10px;
            background-color: #3E4054;
            border-radius: 4px;
            color: white;
            font-size: 12px;
            margin-bottom: 3px;
            p{
                text-align: center;
            }
            .heading{
                color: white;
                font-family: var(--font-semi-bold);
                padding-left: 0px;
                margin-top: 0px;
                font-size: 14px;
            }
            .subtitle{
                margin-top: 0px;
                font-family: var(--font-regular);
                color: #FCFCFC;
                opacity: 0.75;
                padding-top: 3px;
                padding-left: 0px;
                font-size: 12px;
            }
            &.error_desc{
                background-color: #FFEAEA;
                color: #F24242;
                font-size: 14px;
                width: 290px !important;
                margin-left: -145px;
                box-shadow: 0px 0px 2px 0px #aaa;
                padding: 3px 15px;
                min-height: 44px;
                text-align: left;
                p{
                    text-align: left;
                }
                .heading{
                    color: #F24242;
                }
                .go_back{
                    text-align: right;
                    font-family: var(--font-bold);
                    color: #3E4054;
                    line-height: 1.5;
                    font-size: 12px;
                    cursor: pointer;
                    margin-top: -5px;
                    padding: 0;
                    position: absolute;
                    right: 15px;
                    bottom: 3px;
                    text-transform: uppercase;
                }
            }
        }
        .map_marker{
            position: absolute;
            z-index: 1;
            text-align: center;
            left: 50%;
            margin-left: -8px;
            bottom: 50%;
            margin-bottom: -10px;
        }
        .map_loader{
            width:50px;
            height:50px;
            display:flex;
            text-align: center;
            justify-content:center;
            align-items:center;
            margin: 0px auto;
            .circle {
                position:absolute;
                width:0px;
                height:0px;
                border-radius:100%;
                background:#FDA000;
                animation:radar 3s ease-out infinite;
            /*   box-shadow:0px 0px 10px rgba(0,0,0,.5); */
            /*   border:1px solid rgba(255,255,255,.2); */
            }
            
            .circle:nth-of-type(1){
                animation-delay:0.2s;
            }
            
            .circle:nth-of-type(2){
                animation-delay:1s;
            }
            
            .circle:nth-of-type(3){
                animation-delay:1s;
            }
            
            .circle:nth-of-type(4){
                animation-delay:1.4s;
            }
            
            .circle:nth-of-type(5){
                animation-delay:1.8s;
            }
            
            @keyframes radar {
                0% {}
                30% {
                width:50px;
                height:50px;
                }
                100% {
                width:50px;
                height:50px;
                opacity:0;
                }
            }
        }
        .contact-us-add{
            background-color: white;
            padding: 15px 20px;
            border-radius: 4px;
            box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.07);
        }
        .triangle{
            filter: drop-shadow(0 4px 2px rgba(178,178,178,.4));
            height: 12px;
            transform: translateX(0%);
            width: 25px;
            margin: 0px auto;
            &::after{
                margin: 0px auto;
                background: #fff;
                clip-path: polygon(0% 0%,50% 100%,100% 0%);
                content: '';
                height: 12px;
                position: absolute;
                width: 25px;
                top: -1px;
            }
        }
    }
            
}

.disable_dragging{
    pointer-events: none;
}

.error_toast {
    position: fixed;
    top:10px;
    right:10px;
    left:10px;
    color:var(--font-color-light);
    font-family: var(--font-medium);
    padding: 20px;
    border-radius:8px;
    box-shadow: 0 0 2px 0 rgba(0,0,0,0.2);
    z-index:4;
    transform: translateX(calc(100% + 10px));
    transition: transform ease-out 0.3s;
    margin-top: 50px;
    z-index: 5;
    @media (min-width: map-get($grid-breakpoints,md)) {
        margin-top: 0px;
        z-index: 4;
        left:auto;
        top:90px;
        right:20px;
        transform: translateX(calc(100% + 20px));
    }
    &.quick-alert--success{
       background:#00d65f;
    }

    &.quick-alert--error{
        background: var(--font-color-error);
    }
    &.quick-alert--warning {
        background: var(--primary-color-orange);
    }
    &.quick-alert--show{
        transition: transform ease-out 0.3s;
        transform: translateX(0);
    }
   
  }

  .loading-box {
        min-height: 250px;
        @extend %loader-placeholder;
}

.activation__page{
    .row {
        .textfield__nomargin{
            label{
                @media (max-width: map-get($grid-breakpoints,md)) {
                    margin-bottom: 0px;
                }
            }
        }
    }
}


.blogs-section .slick-list {
    padding-bottom: 1px;
}

.document__name{
    font-family: var(--font-bold) !important;
.document__remove{
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width:15px;
    height:15px;
    flex:0 0 16px;
    background-color: #fbbdbd;
    margin-left:auto;
    border-radius: 50%;
    text-align: center;
    text-decoration: none;
    margin-left: 5px;

    &:before {
        display: inline-block;
        font-family:"Icon";
        content:"\e904";
        font-weight: bold;
        color:#000;
        font-size: 5px;

    }
}
}


.slider__height{
    .tab_item_list{
        a{
            padding: 15px !important;
        }
    }
}

.plan_details__plantype_pad{
    .col-md-5{
        padding-left: 60px;
    }
}

.news{
    margin-top: 30px;
    @media(min-width:map-get($grid-breakpoints , lg )) {
    margin-top: 80px;
    }
    .row{
        @media(max-width:map-get($grid-breakpoints , md )) {
            border-radius: 8px;
            box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.1);
            border: solid 1px #ececec;
            background-color: #ffffff;
            margin-left: 0px;
            margin-right: 0px;
            }
            @media (max-width:map-get($grid-breakpoints , md )) and (min-width:map-get($grid-breakpoints , lg )) { 
                div{
                    h3{
                    font-size: 12px !important;
                }
            }
            }
    }
    .pdf_modal{
        overflow: auto;
        @media (min-width: map-get($grid-breakpoints,lg)) {
            &[data-large="true"] {
                div {
                    div{
                    width:1100px;
                    &:before, &:after{
                        display: none;
                    }
                    }
                }
            }
        }
    }

    .react-pdf__Document{
        overflow: auto;
}
}

@media(max-width:map-get($grid-breakpoints , lg )) {
    .plan-details-amc {
        .slick-list {
            overflow: visible !important;
            z-index: 1;
        }
    }
}

.self_serve_cal {
    .react-calendar{
        // left:100px;
        left: 79px;
        @media (min-width: map-get($grid-breakpoints , md )) {
            // right:33px;
            left: 124px;
        }
        @media (min-width: map-get($grid-breakpoints , lg )) {
            // right:33px;
            left: 29%;
        }
    }
}

.slot_selection_cal {
    .react-calendar {
        // width: 100% !important;
        // box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.07);
        // border: 0px !important;
        // border-top: 1px solid black;
        // position: absolute;
        // z-index: 500;
        // max-width: 100% !important;
        // margin-top: 5px;
        // left: none !important;
        // right: none !important;
        // font-size: 12px;
        // // line-height: 14px;
        // font-family: var(--font-semi-bold);
        // @media (min-width: map-get($grid-breakpoints , lg )) {
        //     right:0px;
        //     left:0px;
        // }  
        // @media (min-width: map-get($grid-breakpoints , md )) {
        //    margin: 10px auto;
        // }
        width: 296px !important;
        box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.07);
        border: 0px !important;
        border-top: 1px solid black;
        position: absolute;
        z-index: 500;
        max-width: 296px !important;
        margin-top: 5px;
        left:-10px !important;
        right: 0px !important;
        @media (min-width: map-get($grid-breakpoints , md )) {
            left:-100px !important;
        }    
        .react-calendar__month-view__days__day--weekend {
            color: #565F65;
        }
        .react-calendar__tile {
            // margin: 2px;
            // font-family: var(--font-semi-bold);
            // background: rgba(245, 197, 91, 0.25);
            // border-radius: 100%;
            // font-size: 12px;
            // line-height: 14px;
            // color: #000000;
            background-color: #FFFFFF;
            color: #565F65;
            opacity: 0.5;
            font-size: 12px !important; 
            line-height: 14px;
            font-family: var(--font-semi-bold) !important;
            
            // opacity: 0.5;
        }
        .react-calendar__month-view__weekdays__weekday {
            font-size: 12px;
            line-height: 14px;
            color: #565F65;
            font-family: var(--font-semi-bold);
        }
        .react-calendar__month-view__weekdays {
            // background-color: #FAFAFA;
            text-transform: capitalize;
            text-decoration: none;
            width: 90%;
            // margin: auto;
            // border: 5px solid black;
        }
        .react-calendar__navigation__label {
            order: -1;
            text-align: center;
            color: #3E4054;
            font-size: 14px;
            line-height: 16px;
            font-family: var(--font-semi-bold);
        }
        .react-calendar__month-view__days {
            // display: grid !important;
            // grid-template-columns: 14.2% 14.2% 14.2% 14.2% 14.2% 14.2% 14.2%; 
            width: 90%;
            // margin: auto;
            // border: 5px solid black;
        }
        .react-calendar__navigation {
            margin-bottom: 0px;
            height: 44px;
            margin: auto;
            background-color: #FAFAFA;
            border-radius: 4px 4px 0px 0px;
        }
        .react-calendar__tile--now {
            text-decoration: none;
            background: none;
            border-radius: none;
            color:#000000;
            opacity: 0.5;
        }
        .react-calendar__tile--active {
            background-color: #FDA000;
            border-radius: 75%;
            color: #FFFFFF;
            text-decoration: none;
            opacity: 1;
        }
        .react-calendar__tile--active:enabled:hover,.react-calendar__tile--active:enabled:focus {
            background-color: #FDA000;
            border-radius: 75%;
            color: #FFFFFF;
            text-decoration: none;
            opacity: 1;
        }
        .react-calendar__tile:disabled:not(.react-calendar__tile--now):not(.react-calendar__month-view__days__day--neighboringMonth):not(.react-calendar__tile--active){
            background-color: #FFFFFF;
            color: #565F65;
            opacity: 0.5;
        }
        .react-calendar__month-view__days__day--neighboringMonth:not(.is_date_enabled),.react-calendar__month-view__days__day--neighboringMonth:enabled:hover{
            opacity: 0.2;
            color: #000000;
            background-color: #FFFFFF;
        }
        // .react-calendar__tile .react-calendar__month-view__days__day {
        //     color: black;
        // }
        input {
            width: 90%;
            border-bottom: 1px solid yellow;
            border-top: none;
            border-right: none;
            border-left: none;
            margin-left: 10px;
            padding-bottom: 14px;
            padding-top: 16px;
            font-size: 12px;
            font-family: var(--font-regular);
        }
        input:focus {
            outline: none;
            border-color: #FCBA00;
        }
        a {
            position: absolute;
            font-size: 12px;
            margin-left: -16px;
            font-family: var(--font-bold);
            padding-bottom: 14px;
            padding-top: 16px;
        }
        .hide {
            display: none;
        }
        .react-calendar__month-view__weekdays__weekday abbr {
            text-decoration: none;
        }
        .react-calendar__navigation__arrow {
            min-width: 28px;
            background: none;
            transform: rotate(90deg);
            padding: 10px 0px 0px 0px;
            font-size: 28px;
            color: #000000;
        }
        .is_date_enabled:not(.react-calendar__tile--active){
            background-color: rgba(245, 197, 91, 0.25) !important;
            border-radius: 100%;
            color: #000000 !important;
            opacity: 1 !important;
            // border:2px solid;
        }
    }
}

.inspection_pending {
    color: var(--primary-color-orange);
    color:#ff8c00;
}

.active {
    color: var(--primary-color-green);
}

.under_brand_warranty {
    color: var(--primary-color-orange);
    color:#ff8c00;
}

.expired {
    color: var(--font-color-error);
}

.consumed {
    color: var(--font-color-error);
}

.cancelled {
    color: var(--font-color-error);
}

.activation_pending {
    color: var(--primary-color-orange);
    color:#ff8c00;
}

.unknown {
    color: var(--primary-color-orange);
    color:#ff8c00;
}
.lapsed {
    color: var(--font-color-error);
}
.list-card{
    .slider-wrap {
        .slick-slide {
            & > div:first-child {
                margin-bottom: 58px;
            }
            @media (min-width: map-get($grid-breakpoints, lg)){
                padding-left: 12px;
            }
        }
        .slick-dots {
            text-align:center;
            padding-top: 20px;
            li {
                display:inline-block;
                overflow:hidden;
                width:10px;
                height:4px;
                text-indent:-1000%;
                white-space:nowrap;
                background:#535454;
                opacity:0.4;
                border-radius:2.5px;
                button {
                    display: block;
                    width: 100%;
                    background: transparent;
                    border: none;
                    height: 100%;
                    cursor: pointer;
                    overflow: hidden;
                    text-indent: -1000%;
                    white-space: nowrap;
                    outline: none;
                }
                &.slick-active {
                background:#fdb913;
                opacity:1;
                }
                & + li {
                    margin-left:10px;
                }
            }
            @media (min-width: map-get($grid-breakpoints, lg)){
                padding-bottom: 0;
                li {
                    width: 20px;
                    height:6px;
                    border-radius:2px;
                }
            }
        }
    }
}

.whitepaper-content{
    .content-video{
        margin-top: 30px;
        iframe{
            width: 300px;
            
    @media(min-width:map-get($grid-breakpoints , lg )) {
            width: 706px;
            height: 395px;
    }
            display: block;
            margin-left: auto;
            margin-right: auto;
        }
    }
    p{
        margin-top: 15px;
        font-size: 13px;
    @media(min-width:map-get($grid-breakpoints , lg )) {
        font-size: 16px;
    }
        font-family: var(--font-medium);
        line-height: 1.5;
        margin-bottom: 15px;
    }
    h4{
        margin-top: 35px;
        font-size: 20px;
    @media(min-width:map-get($grid-breakpoints , lg )) {
        font-size: 25px;
    }
        margin-bottom: 5px;
    }
    img{
        display: block;
        width: 300px;
    @media(min-width:map-get($grid-breakpoints , lg )) {
        width: 600px;
    }
    margin-top: 30px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 30px;
    }
    ul{
        margin-top: 20px;
        list-style: disc;
        li{
            font-size: 13px;
    @media(min-width:map-get($grid-breakpoints , lg )) {
            font-size: 16px;
    }
            font-family: var(--font-medium);
            margin-top: 3px;
            line-height: 1.5;
        }
    }
    table{
        margin-top: 30px;
        margin-bottom: 20px;
        font-family: var(--font-medium);
    }
    table, th, td {
        
        border: 1px solid black;
        font-size: 13px;
    @media(min-width:map-get($grid-breakpoints , lg )) {
        font-size: 16px;
    }
    }
    th,td{
        padding: 5px;
    }
    th{
        font-family: var(--font-bold);
        background: #e9f5f9;
    }
    tr{
        .price{
            text-align: center;
        }
    }
    .whitepaper-image{
        p{
            text-align: center;
        }
    }
}

.cross-product-image-border{
    div{
        border: 1px solid #cfcfcf;
        box-shadow: 0 2px 4px 0 rgb(0 0 0 / 20%);
        border-radius: 25px;

        div{
            border: none !important;
            box-shadow: none !important;
            border-radius: 0px !important;
        }

        img{
            padding: 10px !important;
        }

    }
}


.myaccount{
    div{
        div{
            div{
                div{
                    div{
                        p{
                            @media (max-width: map-get($grid-breakpoints, sm)) {
                                font-size: 13px;
                            }
                            position: relative !important;
                            bottom: 0px !important;
                            width: max-content !important;
                            color: var(--primary-color-green) !important;
                            font-family: var(--font-bold);
                        }
                        .MuiFormHelperText-root{
                            color: red !important;
                            font-family: var(--font-regular) !important;
                            bottom: -20px !important;
                            position: absolute !important;
                            
                        }
                    }
                }
            }
        }
    }
}  
.slot_parent {
    .dropdown__menu__item{
      span {
        font-size: smaller !important;
      }
    }
  }
.section_custompad{
    position: relative;
    @media(min-width:map-get($grid-breakpoints , lg )) {
        padding: 56px 0 !important;
    }
}

.bottom_border{
    width: 40px;
    @media(min-width:map-get($grid-breakpoints , md )) {
        width: 80px;
    }
    border: 0.5px solid #9394A0;
    opacity: 0.5;
}
.slot_parent {
    .dropdown__menu__item{
      span {
        font-size: smaller !important;
      }
    }
  }

.scroll_fix{
    overflow-y: hidden !important;
}

.signin_img{
    margin-left: 40px;
    margin-top: 20px;
}
.cart_map{
    margin-top: -120px;
    margin-bottom: 60px;
    .map_containerr{
        margin-bottom: 30px;
        .box_shadow{
            box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.1);
            margin: 0px;
            margin-bottom: 40px;
            .nickname{
                margin-bottom: 10px;
                input{
                    margin-bottom: 20px;
                }
                p{
                    top: 90.5%;
                }
            }
        }
    }
}

.selfserve_addresspop{
    .enter_button{
        padding: 14.5px 24px !important;
        margin: 1px 1px -1px 0px !important;
    }
}

.personal_enter{
    @media(min-width:map-get($grid-breakpoints , lg )) {
        width: 100% !important;
    }
    input{ 
        padding-right: 50px;
        @media (min-width: map-get($grid-breakpoints, lg)) {
            padding-right: 60px !important; 
        }
    }
    .enter_button{
        font-size: 12px;
        padding: 12.5px 10px !important;
        font-family: var(--font-semi-bold);
    }
}

.contact_map{
    pointer-events: none;
    .gmnoprint,.gm-style-iw, .gm-style-iw-c,.gm-fullscreen-control{
        pointer-events: all !important;
    }
    .gm-ui-hover-effect{
        display: none !important;
    }
    .gmnoprint,.gm-fullscreen-control{
        display: none !important;
    }
}