@import './layout';

* {
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
}

html {

    font-size: 10px;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    height: 100%;
    @media (min-width:281px) {
        font-size: 12px;
    }
    @media (min-width: 360px) {
        font-size: 16px;
    }
    @media (min-width:map-get($grid-breakpoints,lg)) and (max-width: map-get($grid-breakpoints,lg-legacy)) {
        font-size: 12px;
    }
    @media (min-width:map-get($grid-breakpoints,lg-legacy)) and (max-width: map-get($grid-breakpoints,xl)) {
        font-size:14px;
    }
    @media (min-width: map-get($grid-breakpoints,xl))  {
        font-size:16px;
    }

}

body {
    font-family: var(--font-regular);
}

html,body  {
    overflow-x: hidden;
    overflow-y: auto;
}

#__next {
    width:100%;
}
  
  ::-webkit-scrollbar  {
    width:6px;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    background:transparent;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb  {
    background:#757575; 
    border-radius: 3px;
  }
  
.justify_content_center{
    justify-content: center;
}

.justify_content_left{
    justify-content: left;
}

.justify_content_right{
    justify-content: right;
}
.justify_content_space_between {
    justify-content:space-between;
}

@keyframes animationLoader {
	from {
		left: -100%;
	}
	to {
		left: 100%;
	}
}