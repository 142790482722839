@import "../../base/layout";

.chat__component {
    width: auto;
    height: auto;
    position: fixed;
    z-index:0;
    text-decoration: none;
    &.chat_button{
        cursor: pointer;
        bottom: 15px;
        right: 25px;
        &.add_item__chaticon {
            bottom: 80px;
        }
    }
    &.scroll_button{
        cursor: pointer;
        bottom: 10px;
        right: 30px;
        img {
            width: 40px;
            height: 40px;
        }
    }
    @media (min-width: map-get($grid-breakpoints, md))  {
        &.chat_button {
            height: auto;
            cursor: pointer;
            bottom: 15px;
            right: 88px;
        }
        &.scroll_button {
            cursor: pointer;
            bottom: 10px;
            right: 30px;
            width: 50px;
            height: 70px;
        }
    }
    ._img{
        text-align: center;
        width: 56px;
        margin: 5px auto;
        @media (min-width: map-get($grid-breakpoints, md))  {
            width: 64px;
        }
    }
    span{
        font-size: 12px;
        color: black;
        padding: 4px;
        background-color: white;
        border-radius: 8px;
        font-family: var(--font-semi-bold);
        @media (min-width: map-get($grid-breakpoints, md))  {
            font-size: 16px;
        }
    }

}
