

%ellipsis {
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

%flex {
    display:flex;
}

%flex-inline {
    display: inline-flex;
}

%align-center {
    align-items: center;
}

%justify-center {
    justify-content: center;
}

%justify-start {
    justify-content: flex-start;
}

%justify-end {
    justify-content: flex-end;
}

%justify-space-between {
    justify-content: space-between;
}

%align-self-center {
    align-self: center;
}


%loader-placeholder {
	width: 100%;
	background-color: whitesmoke !important;
	position:relative;
	overflow:hidden;
	&::before {
		content: '';
		width: 100%;
		height: 100%;
		position:absolute;
		left:0;
		top:0;
    background: rgba(255, 255, 255, 1);
    background: radial-gradient(
      circle,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    animation: animationLoader 1.2s cubic-bezier(0.645, 0.045, 0.355, 1) both
      infinite;
	}
}