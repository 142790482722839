@import "../../../../styles/base/layout";

.__openToast{
    display: flex;
    position: absolute;
    background: #DCFEE0;
    border: 1px solid #4DBD58;
    z-index: 2;
    color: #4DBD58;
    border-radius: 4px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
    padding: 13px 40px 14px 19px;
    margin-right: 10px;
    margin-left: 10px;
    transform: translateX(0);
    font-family: var(--font-semi-bold);
    transition:opacity 200ms cubic-bezier(0.4, 0.0, 0.2, 1),transform 300ms cubic-bezier(0.4, 0.0, 0.2, 1);
    column-gap: 6px;
    text-align: initial;
    align-items: center;
    .__toast_message{
      font-size: 12px;
      font-family: var(--font-semi-bold);
      font-weight: 400;
      @media (min-width: map-get($grid-breakpoints,lg)) {
        font-size: 14px;  
      }
    }
    @media (min-width: map-get($grid-breakpoints,lg)) {
        position: fixed;
        top: 100px;
        width: 480px;
        right: 30px;
        column-gap: 8px;
    }
    @media (max-width: map-get($grid-breakpoints,md)) {
      right: 0;
  }
    @media (max-width: map-get($grid-breakpoints,sm)) {
        top: 80px;
        margin: auto;
        position:fixed;
        left: 0;
        box-shadow: 2px 0 4px 0 #00000019;
        margin-left: 10px;
        margin-right: 10px;
    }
  }
  .__closeToast{
    position: absolute;
    opacity: 0;
    z-index: 9999999;
  }